@import 'Fonts';
@import 'Colors';

.activity-box {
  width: 100%;
  height: 50px;
  box-shadow: 0 0 5px 2px $shadow;
  border-radius: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 10px;

  .activity-icon-div {
    width: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    background-color: $activityIconBg;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
  }

  .activity-icon {
  }

  .activity-name-div {
    color: $text;
    text-align: left;
    flex: 1;
    padding-left: 10px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    @include fontSRegular;
  }

  .activity-time-icon {
    box-sizing: border-box;
    margin: 0 5px;
    height: 20px;
  }

  .activity-time {
    min-width: 32px;
    text-align: left;
    @include fontXSRegular;
    margin: 0 5px;
  }

  .progress-icon {
    margin-left: 5px;
    margin-right: 10px;
    text-align: center;
    height: 20px;
  }
}
