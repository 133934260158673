.flexCentered {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
}

.background {
  background-color: $background;
}

.fullHeight {
  height: 100vh;
}
