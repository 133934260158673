@import 'Fonts';

.image {
  width: 100%;
  height: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
@import 'Colors';

.back-button {
  fill: white;
}

.container-module {
  height: 100%;
  overflow: hidden !important;
  .header {
    position: relative;
    color: $white;
    height: auto;

    .module-image {
      width: 100%;
      height: 100%;
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
    }

    .gradient {
      background: $white;
      background: linear-gradient(180deg, $transparent 52%, $black 100%);
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
    }

    .module-name {
      position: absolute;
      bottom: 40px;
      text-align: center;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 30px;
      display: flex;
      @include fontSBold;
    }

    .module-size {
      position: absolute;
      bottom: 10px;
      text-align: center;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 30px;
      display: flex;
    }

    .top-bar-icons {
      position: absolute;
      top: 10px;
      height: 50px;
      width: 50px;
      display: flex;
      flex: 1;
      align-items: center;
      justify-content: center;
      &.left {
        left: 10px;
        height: 40px;
        width: 40px;
        background-color: $shadow;
        border-radius: 25px;
      }
      &.right {
        right: 10px;
        height: 40px;
        width: 40px;
        background-color: $shadow;
        border-radius: 25px;
      }
    }
  }
}

.not-supported-background {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  background: #e7e4e4de;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
}

.not-supported-modal {
  display: flex;
  height: 10rem;
  width: 80%;
  background: #fff;
  border-top: 1px solid rgb(226, 218, 218);
  box-shadow: 0 1px 10px 2px $shadow;
  overflow: auto;
}

.not-supported-content-div {
  display: flex;
  flex-direction: column;
  margin: 7%;
}

.not-supported-modal-body {
  display: flex;
  padding-top: 5%;
  @include fontMRegular;
}

.not-supported-action-div {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding-top: 14%;
  width: 100%;
}

.not-supported-action-button {
  display: flex;
  width: 20%;
  color: rgb(3, 86, 97);
  @include fontSBold;
}
