@import 'Colors';
@import 'Fonts';

.content-div {
  height: 100vh;
  width: 100%;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  display: flex;
}

.thankyou-text {
  @include fontMMedium;
}

.button-div {
  margin: 4% 4% 1% 4%;
  width: 50%;
  height: 50px;
  background-color: $primary;
  border-radius: 25px;
  box-shadow: 0 7px 20px 2px $shadow;
}

.tybutton-text {
  display: flex;
  flex: 1;
  text-align: center;
  align-items: center;
  justify-content: center;
  color: $white;
  height: 100%;
}
