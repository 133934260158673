@import 'Fonts';
@import 'Colors';
@import 'CommonStyles';

body {
  background-color: $background;
}

.app {
  height: 100%;
  margin: 0;
}

#root {
  height: 100%;
  margin: 0;
}
