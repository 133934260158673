@import 'Fonts';
@import 'Colors';

.modal-container {
  background: $background;
  opacity: 1;
  visibility: visible;
  flex: 1;
  filter: blur(0);
  transition: 1.1s ease-out;
  z-index: 1;
  align-items: center;
  justify-content: center;
  display: flex;
}

.modal {
  width: 75%;
  position: relative;
  margin: 0 16px;
  box-shadow: 0 1px 10px 2px $shadow;
  padding: 16px;
  border-radius: 5px;
}

.cancel-icon {
  right: 16px;
  position: absolute;
}

.quiz-instruction-title {
  width: 100%;
  top: 8%;
  justify-content: center;
  align-items: center;
  text-align: center;
  @include fontMBold;
}

.instruction-box {
  display: flex;
  flex-direction: column;
  padding: 16px;
}

.instruction {
  display: flex;
  flex-direction: row;
  padding-bottom: 5%;
}

.instruction-icon {
  display: flex;
  min-width: 30px;
  height: 30px;
}

.instruction-text {
  display: flex;
  @include fontSRegular;
}

.start-button {
  display: flex;
  width: 80%;
  margin: 0 auto;
  height: 50px;
  background-color: $primary;
  border-radius: 30px;
  justify-content: center;
  align-items: center;
  color: white;
  @include fontMBold;
}
