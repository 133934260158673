@import 'Colors';

.module-listing-view{
  height: 100%;
}

.sticky-wrapper {
  position: relative;
  height: 3rem;
  background-color: $primary;
  width: 100%;
}

.sticky {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1;
}

.back-icon {
  color: white;
  width: 50px;
  height: 3rem;
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  position: absolute;
}

@import 'Fonts';

.my-modules {
  color: white;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex: 1;
  display: flex;
  height: 3rem;
  @include fontMBold;
}

.background-div {
  background-color: #fcfcfc;
  height: 94%;
  width: 100%;
  margin-top: 12%;
  overflow: auto;
}
