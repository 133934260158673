@font-face {
  font-family: 'SFUIText-Regular';
  src: url('../fonts/SFUIText-Regular.otf') format('opentype');
}

@font-face {
  font-family: 'SFUIText-Bold';
  src: url('../fonts/SFUIText-Bold.otf') format('opentype');
}

@font-face {
  font-family: 'SFUIText-Semibold';
  src: url('../fonts/SFUIText-Semibold.otf') format('opentype');
}

@font-face {
  font-family: 'SFUIText-Medium';
  src: url('../fonts/SFUIText-Medium.otf') format('opentype');
}

$font-family-regular: 'SFUIText-Regular';
$font-family-bold: 'SFUIText-Bold';
$font-family-medium: 'SFUIText-Medium';
$font-family-semi-bold: 'SFUIText-Semibold';

$font-size-xxs: 10px;
$font-size-xs: 12px;
$font-size-s: 14px;
$font-size-m: 16px;
$font-size-l: 20px;
$font-size-xl: 24px;

@mixin fontXXSRegular {
  font-family: $font-family-regular;
  font-size: $font-size-xxs;
}

@mixin fontXSRegular {
  font-family: $font-family-regular;
  font-size: $font-size-xs;
}

@mixin fontSRegular {
  font-family: $font-family-regular;
  font-size: $font-size-s;
}

@mixin fontMRegular {
  font-family: $font-family-regular;
  font-size: $font-size-m;
}

@mixin fontLRegular {
  font-family: $font-family-regular;
  font-size: $font-size-l;
}

@mixin fontXLRegular {
  font-family: $font-family-regular;
  font-size: $font-size-xl;
}

@mixin fontXXSSemiBold {
  font-family: $font-family-semi-bold;
  font-size: $font-size-xxs;
}

@mixin fontXSSemiBold {
  font-family: $font-family-semi-bold;
  font-size: $font-size-xs;
}

@mixin fontSSemiBold {
  font-family: $font-family-semi-bold;
  font-size: $font-size-s;
}

@mixin fontMSemiBold {
  font-family: $font-family-semi-bold;
  font-size: $font-size-m;
}

@mixin fontLSemiBold {
  font-family: $font-family-semi-bold;
  font-size: $font-size-l;
}

@mixin fontXLSemiBold {
  font-family: $font-family-semi-bold;
  font-size: $font-size-xl;
}

@mixin fontXXSMedium {
  font-family: $font-family-medium;
  font-size: $font-size-xxs;
}

@mixin fontXSMedium {
  font-family: $font-family-medium;
  font-size: $font-size-xs;
}

@mixin fontSMedium {
  font-family: $font-family-medium;
  font-size: $font-size-s;
}

@mixin fontMMedium {
  font-family: $font-family-medium;
  font-size: $font-size-m;
}

@mixin fontLMedium {
  font-family: $font-family-medium;
  font-size: $font-size-l;
}

@mixin fontXLMedium {
  font-family: $font-family-medium;
  font-size: $font-size-xl;
}

@mixin fontXXSBold {
  font-family: $font-family-bold;
  font-size: $font-size-xxs;
}

@mixin fontXSBold {
  font-family: $font-family-bold;
  font-size: $font-size-xs;
}

@mixin fontSBold {
  font-family: $font-family-bold;
  font-size: $font-size-s;
}

@mixin fontMBold {
  font-family: $font-family-bold;
  font-size: $font-size-m;
}

@mixin fontLBold {
  font-family: $font-family-bold;
  font-size: $font-size-l;
}

@mixin fontXLBold {
  font-family: $font-family-bold;
  font-size: $font-size-xl;
}
