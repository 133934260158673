@import 'Direction';
@import 'Fonts';
@import 'Colors';

.video-activity {
  height: 100vh;
  overflow: hidden;
  width: 100%;

  .flowplayer {
    .seekbar-full-length {
      .questions-icon {
        .display-time {
          left: -15px;
        }
      }
    }
  }
}

.video-activity .flowplayer .fp-controls .fp-duration {
  right: 10px;
}

.video-activity.hidecontrols .fp-controls,
.video-activity.hidecontrols .seekbar-full-length,
.video-activity.hidecontrols .playback-indicator {
  transform: translateY(1500%);
  transition: all 0.5s;
}
.video-activity.hidecontrols .playback-indicator {
  display: none;
}
.video-activity .flowplayer .fp-play svg,
.video-activity .flowplayer .fp-pause svg,
.video-activity .flowplayer .fp-waiting svg {
  pointer-events: auto;
}
.video-activity .flowplayer .fp-ui {
  cursor: default;
}
.video-activity.progressbar-top .flowplayer.is-live-position .fp-ui .fp-play,
.video-activity.progressbar-top .flowplayer.is-live-position .fp-ui .fp-pause {
  display: none;
}
.video-activity.video-nonswipe
  .flowplayer.is-paused
  .fp-ui
  .fp-play
  .fp-play-rounded-fill {
  opacity: 1;
}
.video-activity .flowplayer .b {
  background-image: linear-gradient(
    to top,
    rgba(0, 0, 0, 0.25),
    transparent 15%
  );
}
.video-activity .fp-header {
  padding-left: 0px;
}
.video-activity .fp-header .fp-warning {
  background-color: rgba(0, 0, 0, 0.65);
  border-radius: 0.2em;
  display: inline-block;
  padding: 0.1em 0.3em;
  margin: 0.1em;
  padding-left: 1rem;
}
.video-activity .tapview {
  display: flex;
  z-index: 100000;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  background-color: #fff;
  position: absolute;
  top: 0px;
}
.video-activity .flowplayer .fp-bar > *,
.video-activity .flowplayer .fp-bar-slider > * {
  border-radius: 0px;
}
.video-activity .marker-question {
  width: 30px;
  height: 30px;
}
.video-activity .app-button {
  height: 36px;
  width: 36px;
  line-height: 36px;
  padding: 0 10px;
  background-color: white;
  border-style: solid;
  border-width: 1px;
  border-radius: 18px;
  transition: background-color 0.35s, color 0.35s !important;
  font-family: 'SFUIText-Regular';
  font-size: 14px;
}
.video-activity .app-button.btn-disabled {
  opacity: 0.5;
}
.video-activity .app-button.icon-only {
  border: solid 0px lightgray;
  padding: 0;
}
.video-activity .app-button.icon-only {
  background-color: transparent;
}
.video-activity #flowplayerEl > a {
  opacity: 0 !important;
}
.video-activity .is-splash .seekbar-full-length,
.video-activity .is-splash .playback-indicator {
  visibility: hidden;
}
.video-activity .is-splash .fp-play {
  width: 100vw;
  height: 100vh;
}
.video-activity .seekforward-popup {
  position: absolute;
  left: 0;
  top: 0;
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
}
.video-activity .seekforward-popup .seekforward-popup-block {
  top: 100px;
  background-color: white;
  width: 300px;
  height: 150px;
  z-index: 13;
  border: solid #c92434;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  color: #c92434;
}
.video-activity .seekforward-popup .seekforward-popup-block .app-icon {
  width: 48px;
  height: 48px;
  flex: 2;
}
.video-activity.progressbar-top .playback-indicator {
  top: 0px !important;
}
.video-activity .playback-indicator {
  position: absolute;
  bottom: 66px;
  z-index: 8;
  width: 100%;
  height: 3px;
  background-color: rgba(0, 0, 0, 0.1);
}
@media (max-width: 767px) and (orientation: landscape) {
  .video-activity .playback-indicator {
    bottom: 66px;
  }
}
.video-activity .playback-indicator .played-section {
  background-color: #00b37d;
  border-left: solid 1px rgba(255, 255, 255, 0.3);
  border-right: solid 1px rgba(255, 255, 255, 0.3);
  height: 100%;
  float: left;
}
.video-activity .playback-indicator .played-section .section-info {
  padding: 0 2px;
  top: 100%;
  position: absolute;
  display: none;
  background-color: rgba(0, 0, 0, 0.5);
  color: rgba(255, 255, 255, 0.8);
  font-size: 10px;
}
.video-activity .playback-indicator .played-section:hover .section-info {
  display: block;
}
.video-activity .flowplayer {
  overflow: initial;
  height: 100%;
  border-radius: 0px;
}
.video-activity .flowplayer .fp-menu.fp-subtitle-menu {
  display: none;
}
.video-activity .flowplayer .fp-menu.fp-qsel-menu {
  top: initial !important;
}
.video-activity .flowplayer .fp-fullscreen {
  display: none;
}
.video-activity .flowplayer .fp-controls .fp-qsel {
  position: relative;
  top: 7px;
}
@media (max-width: 767px) {
  .video-activity .flowplayer .fp-controls .fp-qsel {
    position: absolute;
    margin: 13px 10px;
    right: 0;
  }
}
.video-activity .flowplayer .fp-controls {
  height: 16px;
  bottom: 50px;
}
@media (max-width: 767px) and (orientation: landscape) {
  .video-activity .flowplayer .fp-controls {
    bottom: 50px;
  }
}
.video-activity .flowplayer .fp-controls .fp-timeline-tooltip {
  bottom: 56px;
}
.video-activity .flowplayer .fp-controls .fp-timeline,
.video-activity .flowplayer .fp-controls .fp-playbtn,
.video-activity .flowplayer .fp-controls .fp-duration,
.video-activity .flowplayer .fp-controls .fp-elapsed,
.video-activity
  .flowplayer
  .fp-controls
  .add-interactive-question-btn.app-button {
  position: absolute;
}
.video-activity .flowplayer .fp-controls .fp-timeline {
  margin: 0 0 0 0 !important;
  top: 0;
  width: 100%;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  -ms-border-radius: 0;
  -o-border-radius: 0;
  border-radius: 0;
  left: 0;
}
.video-activity .flowplayer .fp-controls .fp-timeline .fp-buffer {
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  -ms-border-radius: 0;
  -o-border-radius: 0;
  border-radius: 0;
}
.video-activity .flowplayer .fp-controls .fp-timeline .fp-progress::before {
  top: -4px;
}
.video-activity .flowplayer .fp-controls .fp-timeline .fp-progress::after {
  top: -8px;
}
.video-activity .flowplayer .fp-controls .fp-playbtn {
  display: none;
  bottom: 5px;
  left: 5px;
}
.video-activity .flowplayer .fp-controls .fp-progress {
  background-color: #c92434;
  height: 100%;
}
.video-activity .flowplayer .fp-controls .fp-duration {
  bottom: 0px;
  font-size: 14px;
  top: -25px;
}
.video-activity .flowplayer .fp-controls .fp-duration::before {
  content: '';
}
.video-activity .flowplayer .fp-controls .fp-elapsed {
  bottom: 10px;
  font-size: 14px;
  left: 0px;
  top: -25px;
}
.video-activity .flowplayer .fp-controls .fp-remaining {
  display: none;
}
.video-activity .flowplayer .fp-controls .fp-volume {
  position: absolute;
  top: 4px;
  display: none;
  left: 150px;
}
.video-activity .flowplayer .settings-panel {
  width: 100%;
  position: absolute;
  bottom: -30px;
  background: #eef1f2;
  height: 150px;
  z-index: 1000000;
}
.video-activity .flowplayer .settings-panel .close-settings {
  float: right;
}
.video-activity .flowplayer .settings-panel .input,
.video-activity .flowplayer .settings-panel .note,
.video-activity .flowplayer .settings-panel .text-center {
  padding-bottom: 10px;
}
.video-activity .flowplayer .settings-panel .small-text {
  font-size: 12px;
}
.video-activity .flowplayer .settings-panel .content {
  padding-top: 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.video-activity .flowplayer .settings-panel .note {
  color: #00b37d;
  font-size: 12px;
}
.video-activity .flowplayer .settings-panel .input .allow-count {
  background: transparent;
  width: 50px;
  text-align: center;
}
.video-activity .flowplayer.fp-branding {
  position: absolute;
  top: 0;
  left: 0;
  width: calc(100% - 110px);
  height: calc(100% - 175px);
  margin-top: 55px;
  pointer-events: none;
  opacity: 0.5;
  visibility: hidden;
  margin-left: 55px;
}
.video-activity .flowplayer.fp-branding .fp-branding-ctrl {
  position: relative;
  left: calc(20% - 180px);
  top: calc(80% - 55px);
  background: rgba(0, 0, 0, 0.3);
  display: inline-block;
  -webkit-transition-property: opacity;
  -moz-transition-property: opacity;
  -ms-transition-property: opacity;
  -o-transition-property: opacity;
  transition-property: opacity;
  -webkit-transition-duration: 500ms;
  -moz-transition-duration: 500ms;
  -ms-transition-duration: 500ms;
  -o-transition-duration: 500ms;
  transition-duration: 500ms;
}
.video-activity .flowplayer.fp-branding .fp-branding-ctrl .client-logo {
  float: left;
  margin: 2px;
}
.video-activity .flowplayer.fp-branding .fp-branding-ctrl .client-logo img {
  height: 50px;
}
.video-activity .flowplayer.fp-branding .fp-branding-ctrl .tag {
  display: flex;
  flex-direction: column;
  color: white;
  margin: 4px;
}
.video-activity .flowplayer.fp-branding.fullscreen {
  height: 100%;
  width: 100%;
  padding: 0;
  z-index: 10000000000;
}
.video-activity .flowplayer .add-interactive-question-btn.app-button {
  bottom: 0;
  background-color: inherit;
  margin-top: 3px;
  color: white;
  border-color: transparent;
  border-radius: 0px;
  right: 0;
}
.video-activity .flowplayer .add-interactive-question-btn.app-button .app-icon {
  fill: white;
}
html.disprz
  .video-activity
  .flowplayer
  .add-interactive-question-btn.app-button:hover {
  background-color: #304268;
}
html.jazz
  .video-activity
  .flowplayer
  .add-interactive-question-btn.app-button:hover {
  background-color: #a94973;
}
html.hamstech
  .video-activity
  .flowplayer
  .add-interactive-question-btn.app-button:hover {
  background-color: #e9743f;
}
html.mahindra
  .video-activity
  .flowplayer
  .add-interactive-question-btn.app-button:hover {
  background-color: #304268;
}
html.naspers
  .video-activity
  .flowplayer
  .add-interactive-question-btn.app-button:hover {
  background-color: #0c6db2;
}
html.aakash
  .video-activity
  .flowplayer
  .add-interactive-question-btn.app-button:hover {
  background-color: #0095d1;
}
html.imarticus
  .video-activity
  .flowplayer
  .add-interactive-question-btn.app-button:hover {
  background-color: #035642;
}
html.iems
  .video-activity
  .flowplayer
  .add-interactive-question-btn.app-button:hover {
  background-color: #656565;
}
.video-activity .flowplayer .seekbar-full-length {
  position: absolute;
  bottom: 66px;
  z-index: 8;
  display: flex;
  width: 100%;
  height: 10px;
}
@media (max-width: 767px) and (orientation: landscape) {
  .video-activity .flowplayer .seekbar-full-length {
    bottom: 66px;
  }
}
.video-activity .flowplayer .seekbar-full-length .seekbar-imaginary {
  position: relative;
  display: flex;
  width: 100%;
}
.video-activity
  .flowplayer
  .seekbar-full-length
  .seekbar-imaginary:hover
  .flowplayer
  .fp-timeline-tooltip.fp-tooltip {
  display: block;
}
.video-activity
  .flowplayer
  .seekbar-full-length
  .seekbar-imaginary
  .download-button {
  width: 30px;
  fill: rgba(255, 255, 255, 0.8);
  position: absolute;
  left: 20px;
  bottom: -50px;
}
.video-activity .flowplayer .seekbar-full-length .questions-icon {
  position: relative;
}
@media (min-width: 1200px) {
  .video-activity
    .flowplayer
    .seekbar-full-length
    .questions-icon:hover
    .app-button.icon-only {
    background-color: white;
  }
  .video-activity
    .flowplayer
    .seekbar-full-length
    .questions-icon:hover
    .display-time {
    display: block;
  }
}
.video-activity
  .flowplayer
  .seekbar-full-length
  .questions-icon:active
  .app-button.icon-only {
  background-color: white;
}
.video-activity
  .flowplayer
  .seekbar-full-length
  .questions-icon:active
  .display-time {
  display: block;
}
.video-activity
  .flowplayer
  .seekbar-full-length
  .questions-icon
  .diamond-marker {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  bottom: -16px;
  width: 0;
}
.video-activity
  .flowplayer
  .seekbar-full-length
  .questions-icon
  .diamond-marker
  .app-button.icon-only {
  position: absolute;
  bottom: 15px;
  left: -18px;
}
.video-activity
  .flowplayer
  .seekbar-full-length
  .questions-icon
  .diamond-marker
  .app-button.icon-only
  .app-icon {
  fill: yellow;
}
.video-activity
  .flowplayer
  .seekbar-full-length
  .questions-icon
  .diamond-marker
  .app-icon {
  fill: yellow;
}
.video-activity .flowplayer .seekbar-full-length .questions-icon .display-time {
  position: absolute;
  bottom: 40px;
  display: none;
  color: white;
  padding: 2px 2px 0 2px;
  background-color: black;
}

.video-activity .flowplayer .fp-ratio {
  visibility: hidden;
}
.video-activity .flowplayer .fp-engine {
  top: 0 !important;
}

.interactive-questions {
  &.outer-div {
    border-radius: 0;
    position: fixed;
    background-color: $white;
    height: 100%;
    width: 100%;
    display: flex;
    top: 0;
    margin: 0;
    z-index: 100;
    .inner-div {
      flex: 1;
      display: flex;
      flex-direction: column;
      padding: 16px;
      overflow: auto;

      .actions-container {
        flex-direction: row;
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
      }

      .options-div {
        display: flex;
        flex-direction: row;
        margin-top: 3%;
        width: 100%;
        height: 50px;
        border-radius: 10px;
        background-color: white;
        border: 1px solid $shadow;
      }

      .selected-options-div {
        background-color: $primary-light;
        border: 1px solid $primary;
      }

      .option-name-div {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 22px;
        background-color: rgb(226, 215, 215);
        border-radius: 50%;
        margin: 4% 0 4% 3%;
      }

      .selected-option-name-div {
        background-color: $primary;
      }

      .correct-option-name-div {
        background-color: $success;
      }

      .wrong-option-name-div {
        background-color: $alert;
      }

      .option-name {
        display: flex;
        justify-content: center;
        align-items: center;
        @include fontXXSBold;
      }

      .selected-option-name {
        color: white;
      }

      .option-text {
        display: flex;
        justify-content: center;
        align-items: center;
        width: auto;
        padding-left: 5%;
        @include fontMRegular;
      }

      .selected-option-text {
        color: $primary;
      }
    }
    .action-button {
      height: 50px;

      .navigation-button {
        display: flex;
        position: absolute;
        bottom: 0;
        width: 30%;
        justify-content: center;
        align-items: center;
        @include fontMBold;
        height: 37px;
        color: $primary;

        &.submit-text {
          right: 2%;
          border: 1px solid $primary;
          border-radius: 10px;
        }
      }
    }
  }
}

.video-quiz-action-button {
  margin-top: 6%;
  display: flex;
  width: 45%;
  justify-content: center;
  height: 3rem;
  border-radius: 25px;
  background-color: $primary;
}

.video-quiz-button-content {
  display: flex;
  align-items: center;
  color: white;
  @include fontSBold;
}
