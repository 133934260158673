@import 'Fonts';
@import 'Colors';

.result-div {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.result-inner-div {
  overflow-y: auto;
  padding: 4% 4% 8% 4%;
}

.you-scored-text {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  height: 1rem;
  @include fontMBold;
}

.display-score-div {
  display: flex;
  justify-content: center;
  align-items: center;
}

.circular-div {
  width: 100px;
  height: 100px;
  justify-content: center;
  align-items: center;
  background-color: $primary;
  border-radius: 50%;
  margin-top: 3%;
}

.display-score {
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
  color: white;
  @include fontMBold;
}

.quiz-result-text {
  display: flex;
  margin-top: 10%;
  text-align: left;
  @include fontSBold;
}

.qiuz-result-div {
  display: flex;
  margin-top: 4%;
  flex-direction: row;
  height: 3rem;
  width: 100%;
}

.quiz-scores {
  min-width: 25%;
  margin-left: 4%;
  display: flex;
  flex-direction: column;
}

.result-score {
  display: flex;
  justify-content: left;
  align-items: center;
  letter-spacing: 0.1rem;
  @include fontMBold;
}

.result-text {
  display: flex;
  margin-top: 10%;
  justify-content: left;
  align-items: center;
  @include fontSRegular;
}

.straight-line {
  display: flex;
  height: 100%;
  width: 0.5px;
  background-color: #868686;
}

.marking-scheme {
  display: flex;
  margin-top: 4%;
  width: 50%;
  height: 2rem;
  justify-content: left;
  align-items: center;
  @include fontSBold;

  .toggle-marking-scheme {
    margin-left: 8px;
    transform: rotate(-90deg);
    &.open {
      transform: rotate(90deg);
    }
  }
}

.scheme-div {
  display: flex;
  flex-direction: column;
  margin-top: 4%;
  height: 8rem;
  border: 1.5px solid #d1c6c6;
  border-radius: 8px;
}

.scheme-row {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 2rem;
  margin-top: 2%;
}

.scheme-content {
  display: flex;
  height: 100%;
  width: 25%;
  margin-left: 2%;
  align-items: center;
  justify-content: left;
  @include fontSBold;
}

.horizontal-line {
  display: flex;
  width: 100%;
  height: 0.5px;
  background-color: #ebe2e2;
}

.section-name-review {
  display: flex;
  margin-top: 4%;
  justify-content: left;
  align-items: center;
  @include fontSSemiBold;
}

.score-breakup-div {
  display: flex;
  flex-direction: column;
  margin-top: 4%;
  height: auto;
  border: 1.5px solid #d1c6c6;
  border-radius: 8px;
}

.score-breakup-row {
  margin: 2%;
  display: flex;
  flex-direction: row;
  width: 92%;
  height: auto;
  margin-top: 2%;
}

.score-breakup-type {
  display: flex;
  justify-content: left;
  align-items: center;
  width: 50%;
  @include fontSSemiBold;
}

.score-breakup-score {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 50%;
  color: #b4a7a7;
  @include fontSSemiBold;
}

.print-score-div {
  display: flex;
  flex-direction: column;
  width: 30%;
  margin-left: 8%;
}

.print-score {
  display: flex;
  justify-content: left;
  align-items: center;
  @include fontSSemiBold;
}

.print-result {
  display: flex;
  justify-content: left;
  align-items: center;
  @include fontSRegular;
}

.review-button-div {
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: center;
  margin-top: 4%;
}

.review-button {
  display: flex;
  padding: 0 3% 0 3%;
  height: 3rem;
  border-radius: 25px;
  justify-content: center;
  align-items: center;
  background-color: $primary;
  color: white;
  @include fontSBold;
}

.reattempt-background {
  position: fixed;
  background: #e7e4e4de;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
}

.reattempt-modal {
  display: flex;
  margin-top: 50%;
  margin-left: 10%;
  height: 12rem;
  width: 80%;
  background: #fff;
  border-top: 1px solid rgb(226, 218, 218);
  box-shadow: 0.7px $shadow;
  overflow: auto;
}

.reattempt-content-div {
  display: flex;
  flex-direction: column;
  margin: 7%;
}

.reattempt-modal-header {
  display: flex;
  @include fontLBold;
}

.reattempt-modal-body {
  display: flex;
  padding-top: 5%;
  @include fontMRegular;
}

.reattempt-action-div {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding-top: 8%;
  width: 100%;
}

.reattempt-action-button {
  display: flex;
  width: 20%;
  color: rgb(3, 86, 97);
  @include fontSBold;
}

.spaces {
  padding-right: 5px;
}
