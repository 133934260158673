@import 'Fonts';
@import 'Colors';

.permission-div {
  position: fixed;
  background: #e7e4e4de;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

.permission-box {
  position: absolute;
  width: 100%;
  bottom: 0;
  background: #fff;
  box-shadow: 0.7px $shadow;
  overflow: auto;
}

.permssion-content {
  display: flex;
  flex-direction: column;
  padding: 20px;
}

.sure-text {
  display: flex;
  justify-content: left;
  align-items: center;
  @include fontMSemiBold;
}

.final-submission {
  display: flex;
  justify-content: left;
  align-items: center;
  margin-top: 4%;
  @include fontSRegular;
}

.permission-buttons {
  display: flex;
  flex-direction: row;
  margin-top: 4%;
  justify-content: flex-end;
  align-items: center;
}

.permission-cancel {
  display: flex;
  width: 30%;
  height: 36px;
  align-content: center;
  justify-content: center;
}

.permission-cancel-text {
  display: flex;
  color: $primary;
  padding-top: 8%;
  @include fontSRegular;
}

.permission-submit {
  display: flex;
  width: 30%;
  height: 36px;
  justify-content: center;
  align-content: center;
  background-color: $primary;
  color: white;
}

.permission-submit-text {
  display: flex;
  padding-top: 8%;
  @include fontSRegular;
}
