@import 'Colors';

$headerHeight: 50;
$footerHeight: 60;

.activity-view-container {
  display: flex;
  flex: 1;
  width: 100%;
  height: 100%;
  background-color: $background;
  flex-direction: column;
  overflow: hidden !important;

  .activity-header-box {
    width: 100%;
    min-height: 50px;
    box-shadow: 0 1px 10px 2px $shadow;
    display: flex;
    align-items: center;
    justify-content: space-around;
    position: relative;
  }

  .activity-back-button {
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50px;
    color: $black;
    position: absolute;
    left: 0;
    height: 50px;
  }
}

.activity-name {
  color: $text;
  text-align: center;
}

.activity-footer {
  margin: 4% 4% 1% 4%;
  width: 92%;
  height: 50px;
  background-color: $primary;
  border-radius: 25px;
  box-shadow: 0 7px 20px 2px $shadow;
}

.footer-text {
  display: flex;
  flex: 1;
  text-align: center;
  align-items: center;
  justify-content: center;
  color: $white;
  height: 100%;
}

.carousel-img {
  float: left;
  margin: 2% 2% 2% 2%;
}

// .video-container {
//   position: fixed;
//   float: left;
//   margin: 4% 0% 0% 0%;
//   width: 94%;
//   right: 0;
//   bottom: 0;
//   min-width: 100%;
//   min-height: 96%;
// }

.video-container {
  display: flex;
  background-color: $background;
  flex-direction: column;
  margin: 4% 0% 0% 0%;
  width: 94%;
  right: 0;
  bottom: 0;
  min-width: 100%;
  min-height: 80%;
  flex: 1;
  position: relative;
}

.document-container {
  width: 100%;
  max-height: 100%;
  border-radius: 10px;
}

.interactive-container {
  .iframe-wrapper {
    height: calc(100% - #{$headerHeight + $footerHeight + 20}px);
    width: 100%;
  }

  #scromFrame {
    height: 100%;
    width: 100%;
  }
}
