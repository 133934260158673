@import 'Colors';

.detail-div {
  height: 50%;
  overflow: auto;
  padding: 4%;
  color: $black;
}

.detail-name {
  display: block;
  text-align: left;
  margin-bottom: 20px;
}
