@import 'Colors';

$headerHeight: 50;
$footerHeight: 60;
$top: 20px;
$navigation-bg-color: $white;

.vertical-carousel-area {
  margin-top: 20px;
  display: flex;
  background-color: $background;
  flex-direction: column;
  flex: 1;
  padding: 0 20px;
  position: relative;
  overflow-y: auto;

  .navigation-area {
    position: fixed;
    background-color: $transparent;
    height: 50px;
    left: 20px;
    right: 20px;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    pointer-events: none;

    .prev-button {
      height: 50px;
      width: 50px;
      border-bottom-right-radius: 10px;
      background-color: $navigation-bg-color;
      transform: rotate(90deg);
      display: flex;
      align-items: center;
      justify-content: center;
      box-shadow: 0 1px 10px 2px $shadow;
      pointer-events: all;

      &.disabled {
        opacity: 0.5;
        box-shadow: 0 1px 10px 0 $shadow;
      }
    }

    .next-button {
      height: 50px;
      width: 50px;
      background-color: $navigation-bg-color;
      border-bottom-left-radius: 10px;
      transform: rotate(-90deg);
      display: flex;
      align-items: center;
      justify-content: center;
      box-shadow: 0 1px 10px 2px $shadow;
      pointer-events: all;
      &.disabled {
        opacity: 0.5;
        box-shadow: 0 1px 10px 0 $shadow;
      }
    }
  }
}

.card {
  display: flex;
  flex: 1;
  border-radius: 10px;
  background-color: $background;
  border-width: 1px;
  box-shadow: 0 0 20px 0 $shadow;
  align-items: center;
}
