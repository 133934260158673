$white: #ffffff;
$black: #000000;
$background: #fcfcfc;
$primary: #0072bc;
$primary-light: #9cccec;
$accent: #162c4c;
$text: #333333;
$shadow: rgba(0, 0, 0, 0.2);
$transparent: rgba(0, 0, 0, 0);
$activityIconBg: rgb(242, 242, 247);
$alert: #f81c53;
$success: #00bf84;
