@import 'Fonts';
@import 'Colors';

.module-box {
  width: 94%;
  box-shadow: 0 0 5px 2px $shadow;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  margin: 4%;
}

.module-info {
  display: flex;
  height: 60%;
  width: 100%;
  flex-direction: row;
}

.module-img {
  height: auto;
  width: 85px;
  display: flex;
  padding: 4%;
}

.image-module {
  min-width: 100%;
  height: 82px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 5px;
}

.module-gradient {
  background: $white;
  background: linear-gradient(180deg, $transparent 52%, $black 100%);
  height: 100%;
  position: absolute;
  top: 0;
}

.module-details {
  display: flex;
  flex-direction: column;
  width: auto;
  padding-top: 16px;
  padding-right: 16px;
}

.name {
  display: flex;
  color: black;
  overflow: hidden;
  @include fontXSBold;
}

.description {
  padding-top: 5%;
  display: flex;
  width: 90%;
  overflow: hidden;
  text-overflow: ellipsis;
  @include fontXXSRegular;
}

.skill {
  display: flex;
  border: 1px solid rgb(194, 183, 183);
  border-radius: 10px;
  width: fit-content;
  max-width: 90%;
  height: 20px;
  margin-top: 10px;
}

.skill-name {
  display: flex;
  padding: 4px 12px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  @include fontXXSRegular;
  color: rgb(194, 183, 183);
  justify-content: center;
  align-items: center;
}

.module-gist {
  display: flex;
  flex-direction: row;
  padding: 0% 4% 2% 4%;
  align-items: center;
}

.self-paced {
  width: 35%;
  display: flex;
  color: black;
  @include fontXSRegular;
  flex: 1;
}

.dot {
  display: flex;
  width: 6px;
  height: 6px;
  background-color: #bbb;
  border-radius: 50%;
}

.activity-count-container {
  align-items: center;
  display: flex;
  color: rgb(156, 146, 146);
  @include fontXSRegular;
  flex: 1;
  justify-content: center;
}

.activity-count {
  padding-left: 4px;
}

.people-completed-container {
  display: flex;
  align-items: center;
  padding-left: 4px;
  color: rgb(156, 146, 146);
  @include fontXSRegular;
  flex: 1;

  .people-completed {
    padding-left: 4px;
  }
}

.break-line {
  display: flex;
  width: 100%;
  height: 4px;
  border-bottom: 1px solid rgb(216, 216, 223);
}

.module-status {
  padding: 0% 4% 0% 4%;
  display: flex;
  width: 90%;
  height: 50px;
  align-items: center;
  flex-direction: row;
}

.status-img {
  display: flex;
  width: 8%;
}

.status-text {
  display: flex;
  width: 27%;
  @include fontXSBold;
}

.status-dot {
  width: 6px;
  height: 6px;
  background-color: #bbb;
  border-radius: 50%;
  display: flex;
}

.due-date {
  padding-left: 2%;
  display: flex;
  width: 28%;
  @include fontXSBold;
}

.months-left {
  display: flex;
  color: rgb(194, 183, 183);
  @include fontXXSRegular;
}
