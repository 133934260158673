@import 'Fonts';
@import 'Colors';

.outer-div {
  display: flex;
  flex: 1;
  margin: 4% 4% 0 4%;
  overflow: auto;
  box-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
}

.inner-div {
  display: flex;
  flex-direction: column;
  padding: 4%;
  width: 100%;
  background-color: $background;
}

.section-name {
  text-align: center;
  padding: 1%;
  width: 100%;
  color: rgb(133, 127, 127);
  @include fontSRegular;
}

.question-type {
  text-align: center;
  padding: 1%;
  width: 100%;
  color: rgb(133, 127, 127);
  @include fontSRegular;
}

.options-div {
  display: flex;
  align-items: center;
  flex-direction: row;
  margin-top: 3%;
  min-height: 50px;
  border-radius: 10px;
  padding: 0 2%;
  background-color: white;
  border: 1px solid $shadow;
}

.selected-options-div {
  background-color: $primary-light;
  border: 1px solid $primary;
}

.correct-options-div {
  background-color: rgb(151, 211, 151);
  border: 1px solid $success;
}

.wrong-options-div {
  background-color: rgb(253, 174, 174);
  border: 1px solid $alert;
}

.option-name-div {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 22px;
  min-width: 22px;
  background-color: rgb(226, 215, 215);
  border-radius: 50%;
}

.selected-option-name-div {
  background-color: $primary;
}

.correct-option-name-div {
  background-color: $success;
}

.wrong-option-name-div {
  background-color: $alert;
}

.option-name {
  display: flex;
  justify-content: center;
  align-items: center;
  @include fontXXSBold;
}

.selected-option-name {
  color: white;
}

.correct-option-name {
  color: white;
}

.wrong-option-name {
  color: white;
}

.option-text {
  width: 100%;
  padding-left: 5%;
  @include fontMRegular;
}

.selected-option-text {
  color: $primary;
}

.correct-option-text {
  color: $success;
}

.wrong-option-text {
  color: $alert;
}

.display-msg {
  display: flex;
  flex-direction: row;
  margin-top: 3%;
  width: 100%;
  min-height: 30px;
  border-radius: 10px;
  justify-content: center;
  align-items: center;
}

.correct-msg {
  background-color: $success;
  color: white;
  @include fontMBold;
}

.wrong-msg {
  background-color: $alert;
  color: white;
  @include fontMBold;
}

.action-button {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  font-family: 'SFUIText-Bold';
  font-size: 10px;
  min-height: 44px;
}

.navigation-button {
  display: flex;
  bottom: 0;
  width: 40%;
  justify-content: center;
  align-items: center;
  @include fontMBold;
  height: 37px;
  color: $primary;

  &.next-text {
    right: 2%;
  }

  &.previous-text {
    left: 2%;
  }

  &.submit-text {
    right: 2%;
    border: 1px solid $primary;
    border-radius: 10px;
  }
}

.timer {
  position: fixed;
  right: 0;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 10px;
  @include fontMBold;

  .remaining-time {
    &.timer-alert {
      color: $alert;
    }

    &.timer-progress {
      color: $success;
    }
  }
}

.validation-error-background {
  position: fixed;
  background: #e7e4e4de;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
}

.validation-error-modal {
  display: flex;
  margin-top: 75%;
  margin-left: 10%;
  height: 7rem;
  width: 80%;
  background: #fff;
  border-top: 1px solid rgb(226, 218, 218);
  box-shadow: 0.7px $shadow;
  overflow: auto;
}

.validation-error-content-div {
  display: flex;
  flex-direction: column;
  margin: 7%;
}

.validation-erorr-modal-body {
  display: flex;
  padding-top: 5%;
  @include fontMRegular;
}

.validation-erorr-action-div {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding-top: 8%;
  width: 100%;
  color: rgb(3, 86, 97);
  @include fontSBold;
}

.explanation-title {
  padding-top: 3%;
  @include fontMBold;
}
